import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const GammaRayBPP = () => {
    const info ={
            title:"Nature's Second Biggest Bangs: The Gamma Ray Burst Zoo",
            presenter:'Dr. Phil Plait',
            presentationType:'Workshop',
            folder:'gammaRayB',
            folderLength:28, 
            slides:null,
            ppt:'csta_grb1_oct102003_c.ppt',
            pptx:'csta_grb1_oct102003_c.pptx'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`/${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <h2>{info.presenter}</h2>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default GammaRayBPP